<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" @click.stop="dialog = true" text>
          <i class="fa fa-map-o" />
          <span class="hidden-md-and-down">
            &nbsp;Show Map
          </span>
        </v-btn>
      </template>
      <span>Show Current Published Map</span>
    </v-tooltip>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title primary-title>
          <h4 class="headline logo_text">
            <i class="fa fa-map-o logo_icon" />&nbsp;Current published map
          </h4>
        </v-card-title>

        <v-divider />

        <v-card-text>
          <div id="mapid" style="height:500px;" />
          <div id="controlid" />
          <p class="text-center">
            <small
              >Map will need to be published to view new users/updated
              data</small
            >
          </p>
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <publish-modal>
            <v-btn slot-scope="{ openDialog }" @click.stop="openDialog" text>
              <span class="hidden-md-and-down">&nbsp;Publish Map</span>
            </v-btn>
          </publish-modal>
          <v-btn color="primary" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SliderMap } from "slider-map";
const publishModal = () =>
  import(/* webpackChunkName: "publishModal" */ "./PublishModal.vue");

export default {
  name: "ViewPublishedMap",
  components: {
    publishModal
  },
  data() {
    return {
      dialog: false
    };
  },
  computed: {
    ...mapGetters(["currMap"])
  },
  watch: {
    dialog(val) {
      if (!val) return;
      this.showMap();
    }
  },
  methods: {
    showMap() {
      this.$nextTick(async () => {
        const map = new SliderMap(this.currMap.id, "mapid", "controlid");
        map.create();
      });
    }
  }
};
</script>
